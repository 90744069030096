import Glide from '@glidejs/glide';
import Navigation from '../components/glide-navigation';
import Lazy from '../components/glide-lazy';

let container;
const map = new Map();

function onResize() {
    let sliderInitMinimal;

    if (window.matchMedia('(max-width: 1024px)').matches) {
        sliderInitMinimal = 1;
    } else {
        sliderInitMinimal = 2;
    }

    if (container) {
        Array.from(container.querySelectorAll('.js-article-slider')).forEach((el) => {
            const slides = Array.from(el.querySelectorAll('.glide__slide'));
            const currentSlider = map.get(el);

            if (slides.length >= sliderInitMinimal) {
                if (!currentSlider) {
                    const slider = new Glide(el, {
                        perView: 2,
                        gap: 80,
                        rewind: false,
                        type: 'carousel',
                        peek: { before: 0, after: 340 },
                        breakpoints: {
                            1680: {
                                peek: { before: 0, after: 140 },
                            },
                            1366: {
                                gap: 64,
                                peek: { before: 0, after: 140 },
                            },
                            1024: {
                                perView: 1,
                                gap: 20,
                                peek: { before: 0, after: 140 },
                            },
                            767: {
                                perView: 1,
                                gap: 20,
                                peek: { before: 0, after: 0 },
                            },
                        },
                    });

                    // slider.on('run.before', () => {
                    //     console.log(slider);
                    //     setTimeout(() => {
                    //         slider.disable();
                    //     }, 100);
                    // });

                    slider.mount({ Lazy, Navigation });
                    map.set(el, slider);
                }
            } else if (currentSlider) {
                currentSlider.destroy();
                map.delete(el);
            }
        });
    }
}

function init(_container = document) {
    container = _container;
    onResize();
    window.addEventListener('resize', onResize);
}

function destroy() {
    window.removeEventListener('resize', onResize);

    Array.from(container.querySelectorAll('.js-article-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });

    container = null;
}

const _module = { init, destroy };

export default _module;
