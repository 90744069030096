const header = document.querySelector('.js-header');
const headerHeight = header.offsetHeight;
const headerToScreenRatio = parseInt(headerHeight / (window.innerHeight / 100) + 1, 10) / 2;
const commonRootMargin = `-${headerToScreenRatio}% 0% -${100 - headerToScreenRatio}% 0%`;
const callback = (entries) => {
    entries.forEach((entry) => {
        if (header) {
            if (entry.isIntersecting) {
                if (entry.target.dataset.bgColor && entry.target.dataset.bgColor !== state.bgColor) {
                    header.classList.remove(state.bgColor);
                    header.classList.add(entry.target.dataset.bgColor);
                    state.bgColor = entry.target.dataset.bgColor;
                }
                if (entry.target.dataset.color && entry.target.dataset.color !== state.color) {
                    header.classList.remove(state.color);
                    header.classList.add(entry.target.dataset.color);
                    state.color = entry.target.dataset.color;
                }
            }
        }
    });
};

const map = new WeakMap();

const state = {
    bgColor: 'white-bg',
    color: 'dark-color',
};

const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container = document) {
    const elements = Array.from(container.querySelectorAll('.js-section'));

    elements.forEach((el) => {
        const rootMargin = el.dataset.rootMargin || commonRootMargin;
        const observer =
            rootMargin === commonRootMargin
                ? commonObserver
                : new IntersectionObserver(callback, { rootMargin, threshold: [0, 1] });

        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy(container = document) {
    const elements = Array.from(container.querySelectorAll('.js-section'));

    elements.forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
            map.delete(el);
        }
    });
}

export default { init, destroy };
