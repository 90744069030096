import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<
    HTMLFormElement,
    {
        inputs: HTMLInputElement[];
        sender: Record<string, any>;
        submitFn: (event: Event) => void;
        onBlurFn: () => void;
        onFocusFn: () => void;
    }
>();

function sendTarget(strTarget: string) {
    if (strTarget !== undefined && ym) {
        ym(87118866, 'reachGoal', strTarget);
    }
    if (strTarget!== undefined && gtag) {
        gtag('event', strTarget);
    }
}

const showFormMessage = (form: HTMLFormElement, message: string | HTMLElement) => {
    const messageContainer = form.querySelector('.js-form-message');

    if (messageContainer) {
        messageContainer.innerHTML = message;
    }
};

const showLoadingMessage = (form: HTMLFormElement) => {
    const messageContainer = form.querySelector<HTMLElement>('.js-form-message');

    if (messageContainer) {
        const loadingMessage = messageContainer.dataset.loadingMessage;

        if (loadingMessage) {
            messageContainer.innerHTML = loadingMessage;
        }
    }
};

const hideFormMessages = (form: HTMLFormElement) => {
    form.classList.remove('is-error');
    form.classList.remove('is-success');
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));
    forms.forEach((form) => {
        let isSubmitting = false;

        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'end',
            },
        });

        const sender = createFormSender(form, {
            shouldClearInputs: true,
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: (response) => {
                if (response.status === 'success') {
                    const formTarget = form.getAttribute('data-target');
                    form.classList.add('is-success');
                    showFormMessage(form, response.data.message);
                    if (formTarget) {
                        sendTarget(formTarget);
                    }
                } else if (response.status === 'error') {
                    form.classList.add('is-error');
                    let ul = '';
                    if (response.errors && response.errors.length > 0) {
                        ul = '<ul>';
                        response.errors.forEach(error => {
                            ul += `
                                <li>${error.message}</li>
                            `;
                        });
                        ul += '</ul>';
                    }
                    showFormMessage(form, ul);
                } else {
                    form.classList.add('is-success');
                    showFormMessage(form, 'Something went wrong!');
                }
            },
            onError: (response) => {
                form.classList.add('is-error');
                showFormMessage(form, 'Upload failed');
            },
            onComplete: () => {
                setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            let timer: NodeJS.Timeout;
            const isFormValid = validator.validate();

            if (isFormValid) {
                isSubmitting = true;

                timer = setTimeout(() => {
                    form.classList.add('is-load');
                }, 300);
                form.classList.add('is-overlay');
                showLoadingMessage(form);
                sender.send().finally(() => {
                    isSubmitting = false;
                    clearTimeout(timer);
                    form.classList.remove('is-load');
                    form.classList.remove('is-overlay');
                });
            }
        }

        function onFocus(this: HTMLInputElement) {
            validator.clearInput(this);
        }

        function onBlur(this: HTMLInputElement) {
            validator.validateInput(this);
        }

        validator.inputs.forEach((input) => {
            // input.addEventListener('focus', onFocus);
            // input.addEventListener('blur', onBlur);
        });
        form.addEventListener('submit', submitFn);
        map.set(form, { inputs: validator.inputs, sender, submitFn, onFocusFn: onFocus, onBlurFn: onBlur });
    });
}

function destroy(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));

    forms.forEach((form) => {
        const data = map.get(form);

        if (data) {
            data.inputs.forEach((input) => {
                input.removeEventListener('focus', data.onFocusFn);
                input.removeEventListener('blur', data.onBlurFn);
            });
            form.removeEventListener('submit', data.submitFn);
        }
    });
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
