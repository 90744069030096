import Glide from '@glidejs/glide';
import Navigation from '../components/glide-navigation';
import Lazy from '../components/glide-lazy';

let sliderInitMinimal;
let slides = [];
let isSliderInited = false;
let slider;
let sliderEl;

function sliderInit(sliderEl) {
    if (sliderEl) {
        slider = new Glide(sliderEl, {
            perView: 2,
            gap: 80,
            rewind: false,
            type: 'carousel',
            peek: { before: 0, after: 340 },
            breakpoints: {
                1680: {
                    peek: { before: 0, after: 140 },
                },
                1366: {
                    gap: 64,
                    peek: { before: 0, after: 140 },
                },
                1200: {
                    perView: 1,
                    gap: 64,
                    peek: { before: 0, after: 140 },
                },
                1024: {
                    perView: 1,
                    gap: 20,
                    peek: { before: 0, after: 140 },
                },
                767: {
                    perView: 1,
                    gap: 20,
                    peek: { before: 0, after: 0 },
                },
            },
        });

        slider.mount({ Lazy, Navigation });
        isSliderInited = true;
    }
}

function sliderDestroy() {
    isSliderInited = false;
}

function onResize() {
    if (window.matchMedia('(max-width: 1200px)').matches) {
        sliderInitMinimal = 1;
    } else if (window.matchMedia('(max-width: 1366px)').matches) {
        sliderInitMinimal = 2;
    } else {
        sliderInitMinimal = 3;
    }

    if (slides.length >= sliderInitMinimal) {
        if (!isSliderInited) {
            sliderInit(sliderEl);
        }
    } else if (isSliderInited) {
        sliderDestroy();
    }
}

function init(container = document) {
    sliderEl = container.querySelector('.js-articles-slider');

    if (sliderEl) {
        slides = sliderEl.querySelectorAll('.glide__slide');
        onResize();
        window.addEventListener('resize', onResize);
    }
}

function destroy(container = document) {
    window.removeEventListener('resize', onResize);

    sliderDestroy();
    sliderInitMinimal = null;
    slider = null;
    sliderEl = null;
    slides = [];
    isSliderInited = null;
}

const _module = { init, destroy };

export default _module;
