import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

const map = new WeakMap();

function init(container = document) {
    const revealEls = Array.from(container.querySelectorAll('.js-clip-path-reveal'));
    revealEls.forEach((el) => {
        const elSplitted = new SplitText(el, { type: 'lines' });
        const elLines = elSplitted.lines;
        const trigger = ScrollTrigger.create({
            trigger: el,
            start: `top ${100 - parseInt((el.offsetHeight / window.innerHeight) * 100, 10)}%`,
            animation: gsap.fromTo(
                elLines,
                { clipPath: 'polygon(0 0, 100% 0%, 100% 0%, 0% 0%)', y: 40, stagger: 0.1, duration: 0.4, opacity: 0 },
                { clipPath: 'polygon(0 0, 100% 0%, 100% 110%, 0% 110%)', y: 0, duration: 0.4, opacity: 1 },
            ),
        });
        map.set(el, trigger);
    });
}

function destroy(container = document) {
    const revealEls = Array.from(container.querySelectorAll('.js-clip-path-reveal'));

    revealEls.forEach((el) => {
        const trigger = map.get(el);
        if (trigger) {
            trigger.kill();
        }
    });
}

const _module = { init, destroy };

export default _module;
