const revealItemsObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                revealItemsObserver.unobserve(entry.target);
                entry.target.classList.add('is-reveal');
            }
        });
    },
    { rootMargin: '0% 0% 0% 0%', threshold: [0, 1] },
);

function init(container = document) {
    Array.from(container.querySelectorAll('.js-reveal')).forEach((el) => {
        revealItemsObserver.observe(el);
    });
}

function destroy(container = document) {
    Array.from(container.querySelectorAll('.js-reveal')).forEach((el) => {
        revealItemsObserver.unobserve(el);
    });
}

const _module = { init, destroy };

export default _module;
