import Lazyload from 'vanilla-lazyload';

export default (Glide: any, Components: any, Events: any) => {
    const slides = Array.from(Glide.selector.querySelectorAll('.glide__slide')) as HTMLElement[];

    const getPrevIndex = (currentIndex: number, step = 1) => (currentIndex - step + slides.length) % slides.length;
    const getNextIndex = (currentIndex: number, step = 1) => (currentIndex + step) % slides.length;

    const loadPrevNext = (currentIndex: number, step = 1) => {
        const prevSlideImg = slides[getPrevIndex(currentIndex, step)]?.querySelector('img');
        const nextSlideImg = slides[getNextIndex(currentIndex, step)]?.querySelector('img');
        [prevSlideImg, nextSlideImg].forEach((img) => {
            if (img) {
                Lazyload.load(img, { elements_selector: '.glide-lazy' });
            }
        });
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                observer.unobserve(entry.target);
                loadPrevNext(Glide.index);
            }
        });
    });

    observer.observe(Glide.selector);

    Glide.on('run.after', () => {
        loadPrevNext(Glide.index);
    });

    Glide.on('destroy', () => {
        observer.disconnect();
    });

    return {};
};
