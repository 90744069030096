import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

let header;
let headerHeight;
const burgerPopup = document.querySelector('[data-lit-popup="burger"]');

function scrollToElement(event) {
    if (event) {
        event.preventDefault();
    }

    const id = this.dataset.scrolltoId;
    if (!id) {
        console.error('[scrollto] No id provided.');
        return;
    }
    if (burgerPopup && burgerPopup.classList.contains('lit-popup--opened')) {
        burgerPopup.close();
    }
    const targetElement = document.getElementById(id) ? document.getElementById(id) : document.querySelector(id);
    if (targetElement) {
        const offset = targetElement.dataset.scrollToOffset ? parseFloat(targetElement.dataset.scrollToOffset) : 30;
        let scrollTarget;
        if (targetElement.dataset.calcScrollTop) {
            scrollTarget = targetElement.dataset.calcScrollTop;
        } else {
            scrollTarget = targetElement.offsetTop;
        }
        const distation = scrollTarget < 1000 ? 999 : Math.abs(window.pageYOffset - scrollTarget);
        let k;
        if (distation > 10000) {
            k = 7000;
        } else if (distation > 5000) {
            k = 5000;
        } else if (distation > 3000) {
            k = 2500;
        } else if (distation > 1500) {
            k = 1500;
        } else {
            k = 1000;
        }
        const speed = distation / k;
        gsap.to(window, {
            duration: speed,
            scrollTo: {
                y: targetElement,
                offsetY: headerHeight + offset,
            },
            ease: 'power2.easeOut',
            onComplete: () => {
                if (targetElement.classList.contains('js-collapse-item')) {
                    const collapse = targetElement.closest('.js-collapse-item-wrapper').querySelector('app-collapse');
                    setTimeout(() => {
                        collapse.expand();
                    }, k);
                }
                if (targetElement.classList.contains('js-tab-item')) {
                    const link = targetElement.querySelector('.tab__link');
                    let event = new Event('click', { bubbles: true, cancelable: false });
                    setTimeout(() => {
                        link.dispatchEvent(event);
                    }, k);
                }
            },
        });
    }
}

function init(container = document) {
    const anchorLinks = Array.from(container.querySelectorAll('[data-scrollto-id]'));
    header = document.querySelector('.js-header');
    headerHeight = header.offsetHeight;
    if (anchorLinks.length) {
        anchorLinks.forEach((link) => link.addEventListener('click', scrollToElement));
    }
}

function destroy(container = document) {
    const anchorLinks = Array.from(container.querySelectorAll('[data-scrollto-id]'));
    header = null;
    headerHeight = null;
    if (anchorLinks.length) {
        anchorLinks.forEach((link) => link.removeEventListener('click', scrollToElement));
    }
}

const _module = { init, destroy };

export default _module;
