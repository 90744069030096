import delegate from 'delegate';
import { fetchLightgallery } from '../dynamic-modules';

const map = new Map<HTMLElement, any>();
let delegation: any;

async function onClick(event: any) {
    event.preventDefault();
    const target = event.delegateTarget;
    const currentLg = map.get(target);

    if (!currentLg) {
        const { default: lightGallery } = await fetchLightgallery();
        const noDraggable = typeof target.dataset.lgNoDraggable === 'string';
        const lg = lightGallery(target, {
            selector: '.js-lightgallery-link',
            download: false,
            counter: typeof target.dataset.lgNoCounter !== 'string',
            enableDrag: !noDraggable,
            enableSwipe: !noDraggable,
            mobileSettings: {
                showCloseIcon: true,
            },
        });
        lg.openGallery();
        map.set(target, lg);
    }
}

function init(container: Element | Document = document) {
    delegation = delegate(container, '.js-lightgallery', 'click', onClick);
}

function destroy(container: Element | Document = document) {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const module = { init, destroy };

export default module;
