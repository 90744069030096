import delegate from 'delegate';

function addElement(e) {
    e.stopPropagation();
    if (e.delegateTarget.disabled) {
        return;
    }
    let addDiv = document.createElement('div'),
        mValue = Math.max(e.delegateTarget.clientWidth, e.delegateTarget.clientHeight),
        rect = e.delegateTarget.getBoundingClientRect();
    let sDiv = addDiv.style,
        px = 'px';
    let container = e.delegateTarget.querySelector('.btn__bg');
    sDiv.width = sDiv.height = mValue + px;
    sDiv.left = e.clientX - rect.left - mValue / 2 + px;
    sDiv.top = e.clientY - rect.top - mValue / 2 + px;

    addDiv.classList.add('pulse');
    container.appendChild(addDiv);
    setTimeout(() => {
        addDiv.remove();
    }, 700);
}

let delegation;

function init(container = document) {
    delegation = delegate(
        '.btn-ripple',
        'click',
        (e) => {
            addElement(e);
        },
        false,
    );
}

function destroy(container = document) {
    delegation?.destroy();
}

export default { init, destroy };
