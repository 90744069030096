import './LitPopup.scss';
import { LitElement } from 'lit';
import LitPopup from 'lit-popup';
import { getCSSCustomProp } from '../../utils/css';
import scrollLock from 'scroll-lock';

export interface LitPopupElement {
    instance: LitPopup;
    _wasBodyLocked: boolean;
    _leaveTimeout: NodeJS.Timeout;
}

const NO_SCROLL_CLASS = 'no-scroll';

export class LitPopupElement extends LitElement {
    constructor() {
        super();
        this._wasBodyLocked = false;
    }

    static get properties() {
        return {
            name: {
                type: Boolean,
            },
            opened: {
                type: String,
            },
        };
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        const name = this.getAttribute('data-lit-popup');
        const leaveDurationInSeconds = getCSSCustomProp(this, '--leave-duration', 'number') as number;

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }
        this.instance = new LitPopup(name, {
            openAnimation: () => {
                return new Promise ((resolve) => {
                    setTimeout(() => {
                        resolve("animation end");
                    }, leaveDurationInSeconds)
                })
            },
            closeAnimation: () => {
                return new Promise ((resolve) => {
                    setTimeout(() => {
                        resolve("animation end");
                    }, leaveDurationInSeconds)
                })
            },
            onOpen: () => {
                const rect = this.getBoundingClientRect();
                const windowWidth = window.innerWidth;
                if (this.dataset.noScrollLock === undefined) {
                    this._lockBodyScroll();
                }
                if (rect.left < 0) {
                    this.style.transform = `translate(calc(-50% + ${rect.left * -1}px), -50%)`;
                }
                if (rect.right > windowWidth) {
                    this.style.transform = `translate(calc(-50% + ${windowWidth - rect.right}px), -50%)`;
                }
                clearTimeout(this._leaveTimeout);
                if (this.dataset.scrollToPopup) {
                    const header: HTMLElement | any = document.querySelector('.js-header');
                    const headerHeight = header.offsetHeight;
                    if (window.matchMedia('(max-width: 1023px)').matches) {
                        window.scrollTo({top: window.scrollY - headerHeight + rect.top - (((window.innerHeight - headerHeight) - this.offsetHeight) / 2), behavior: "smooth"});
                    } else {
                        if (rect.top < headerHeight) {
                            window.scrollBy({top: rect.top - headerHeight, behavior: "smooth"});
                        } else if (rect.top > window.innerHeight - this.offsetHeight) {
                            window.scrollBy({top: (window.innerHeight - rect.top - this.offsetHeight) * -1, behavior: "smooth"});
                        }
                    }
                }
            },
            onOpenComplete: () => {
                this._addClassToCloser();
                this._addClassesToTrigger();
                const focusableOnOpenElement = this.renderRoot.querySelector<HTMLElement>('[data-focus-on-popup-open]');
                setTimeout(() => focusableOnOpenElement?.focus({ preventScroll: true }), 50);
            },
            onClose: () => {
                this._removeClassesToTrigger();
                this._unlockBodyScroll();
                this._removeClassFromCloser();
            },
            onCloseComplete: () => {
                this.style.transform = '';
                this._unlockBodyScroll();
                this._removeClassFromCloser();
            }
        });
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);
        if (name === 'opened') {
            if (typeof newVal === 'string') {
                this.instance.open();
            } else {
                this.instance.close();
            }
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._leaveTimeout);

        if (this.instance) {
            this.instance.destroy();
        }
    }

    open() {
        this.instance?.open();
    }

    close() {
        this.instance?.close();
    }

    protected _lockBodyScroll() {
        if (scrollLock.getScrollState()) {
            scrollLock.disablePageScroll();
        }
    }

    protected _unlockBodyScroll() {
        if (!scrollLock.getScrollState()) {
            scrollLock.enablePageScroll();
        }
    }

    protected _addClassToCloser() {
        const name = this.getAttribute('data-lit-popup');
        const closer = document.querySelector(`[data-lit-popup-closer=${name}`);
        if (closer) {
            closer.classList.add('is-active');
        }
    }

    protected _removeClassFromCloser() {
        const name = this.getAttribute('data-lit-popup');
        const closer = document.querySelector(`[data-lit-popup-closer=${name}`);
        if (closer) {
            closer.classList.remove('is-active');
        }
    }

    protected _addClassesToTrigger() {
        const name = this.getAttribute('data-lit-popup');
        const opener = document.querySelector(`[data-lit-popup-open=${name}`);
        if (opener) {
            opener.classList.add('is-open');
        }
    }

    protected _removeClassesToTrigger() {
        const name = this.getAttribute('data-lit-popup');
        const opener = document.querySelector(`[data-lit-popup-open=${name}`);
        if (opener) {
            opener.classList.remove('is-open');
        }
    }

}

customElements.define('app-lit-popup', LitPopupElement);

declare global {
    interface HTMLElementTagNameMap {
        'app-lit-popup': LitPopupElement;
    }
}
