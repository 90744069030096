let buttons: HTMLElement[] = [];

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            buttons.forEach((btn) => {
                if (entry.isIntersecting) {
                    btn.classList.remove('is-hidden');
                } else {
                    btn.classList.add('is-hidden');
                }
            });
        });
    },
    { rootMargin: '250% 0% -250% 0%' },
);

function onClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

function init() {
    buttons = Array.from(document.querySelectorAll('.js-scrolltop'));
    buttons.forEach((el) => {
        el.addEventListener('click', onClick);
    });

    observer.observe(document.body);
}

function destroy() {
    buttons.forEach((el) => {
        el.removeEventListener('click', onClick);
    });
    buttons = [];
}

const _module = { init, destroy };

export default _module;
