export default (Glide: any, Components: any, Events: any) => {
    const arrowContainers = Array.from(Glide.selector.querySelectorAll('.js-slider-nav')) as HTMLElement[];
    let isInitialized = false;

    function createArrows(container: HTMLElement) {
        container.innerHTML = `
            <div class="slider-nav" data-glide-el="controls">
                <button class="slider-nav__item js-slider-nav-item" aria-label="previous slide" data-glide-dir="<">
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path d="M21.7429 14.1419L9.01494 14.1419" stroke="white" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M14.6719 21.2129L7.60081 14.1418L14.6719 7.07076" stroke="white" stroke-width="1.2" stroke-linecap="square"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="20" height="20" fill="white" transform="translate(14.6719 28.2842) rotate(-135)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </button>
                <button class="slider-nav__item js-slider-nav-item" aria-label="next slide" data-glide-dir=">">
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip-arrow-right)">
                            <path d="M7.74541 14.1419L20.4733 14.1419" stroke="white" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M14.8164 21.2129L21.8875 14.1418L14.8164 7.07076" stroke="white" stroke-width="1.2" stroke-linecap="square"/>
                        </g>
                        <defs>
                            <clipPath id="clip-arrow-right">
                                <rect width="20" height="20" fill="white" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 14.8164 28.2842)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
        `;
    }

    function init() {
        if (isInitialized) return;

        arrowContainers.forEach((container) => {
            createArrows(container);
        });

        isInitialized = true;
    }

    function destroy() {
        console.log('destroy nav');
        arrowContainers.forEach((container) => {
            container.innerHTML = '';
        });

        isInitialized = false;
    }

    let activeSlide: HTMLElement | null;

    Events.on('build.before', init);
    Events.on('run', () => {
        activeSlide = Glide.selector.querySelector('.glide__slide--active') as HTMLElement | null;
        activeSlide?.classList.add('glide__slide--leaving');
    });
    Events.on('run.after', () => {
        if (activeSlide) {
            activeSlide.classList.remove('glide__slide--leaving');
            activeSlide = null;
        }
    });
    Events.on('destroy', destroy);

    return {};
};
