import barba from '@barba/core';
import ScrollTrigger from 'gsap/ScrollTrigger';
import headerThemeObserver from '../inits/header-theme-observer';
import scrollToElement from '../inits/scroll-to-element';
import clipPathReveal from '../inits/clip-path-reveal';
import fadeReveal from '../inits/fade-reveal';
import { lazyload } from '../inits/lazyload';
import ajaxForms from '../inits/ajax-forms';
import inputMasks from '../inits/masks';
import inputFocus from '../inits/input-focus';
import lightgallery from '../inits/lightgallery';
import articleSlider from '../inits/article-slider';
import articlesSlider from '../inits/articles-slider';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import scrolltop from '../inits/scrolltop';

export function initBaseView() {
    barba.hooks.beforeEnter((data) => {
        headerThemeObserver.init(data?.next.container);
        scrollToElement.init(data?.next.container);
        ajaxForms.init(data?.next.container);
        inputMasks.init(data?.next.container);
        inputFocus.init(data?.next.container);
        lightgallery.init(data?.next.container);
        articleSlider.init(data?.next.container);
        articlesSlider.init(data?.next.container);
        scrolltop.init();
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }
        scrollToElement.init(data?.next.container);
        lazyload.update();

        const footer = document.querySelector('.js-footer');
        clipPathReveal.init(footer);
        fadeReveal.init(footer);
    });

    barba.hooks.afterEnter((data) => {
        clipPathReveal.init(data?.next.container);
        fadeReveal.init(data?.next.container);
    });

    barba.hooks.beforeLeave((data) => {
        const scrollTriggers = ScrollTrigger.getAll();

        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.kill();
            });
        }

        fadeReveal.destroy(data?.current.container);
        clipPathReveal.destroy(data?.current.container);
        headerThemeObserver.destroy(data?.current.container);
        scrollToElement.destroy(data?.current.container);
        ajaxForms.destroy(data?.current.container);
        inputMasks.destroy(data?.current.container);
        inputFocus.destroy(data?.current.container);
        lightgallery.destroy(data?.current.container);
        articleSlider.destroy(data?.current.container);
        articlesSlider.destroy(data?.current.container);
        scrolltop.destroy();
    });

    barba.hooks.leave(() => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }
    });

    barba.hooks.afterLeave(() => {
        const footer = document.querySelector('.js-footer');
        const footerRevealed = footer ? Array.from(footer?.querySelectorAll('.js-reveal')) : [];
        const footerClipPathRevealed = footer ? Array.from(footer?.querySelectorAll('.js-clip-path-reveal')) : [];

        fadeReveal.destroy(footer);
        clipPathReveal.destroy(footer);

        footerRevealed.forEach((item) => item.classList.remove('is-reveal'));
        footerClipPathRevealed.forEach((item) => item.classList.remove('is-reveal'));
    });
}
