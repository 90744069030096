import gsap from 'gsap';
import { CountUp } from 'countup.js';
import barba from '@barba/core';
import { timeout } from '../utils/timeout';

export const preloaderAnimationDuration = 0.7; // sec

function init() {
    const header = document.querySelector('.js-header');
    const main = document.querySelector('.js-main');

    function createPreloader() {
        const preloader = document.querySelector<HTMLElement>('.js-preloader');
        const counterContainer = document.querySelector<HTMLElement>('.js-preloader-counter-container');
        const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
        const line = document.querySelector<HTMLElement>('.js-preloader-line');
        let loaded = false;

        const state = {
            completed: false,
        };

        document.body.classList.add('no-scroll');

        function leave(): Promise<void> {
            return new Promise((resolve) => {
                document.body.classList.remove('no-scroll');

                document.dispatchEvent(new Event('preloader-leave'));

                barba.hooks.afterEnter(() => {
                    if (!loaded) {
                        loaded = true;
                    }
                });

                const tl = gsap.timeline();

                tl.to(counterContainer, { duration: 0.9, yPercent: 100, opacity: 0 })
                    .to(
                        preloader,
                        {
                            duration: 0.5,
                            opacity: 0,
                            onComplete: () => {
                                preloader?.classList.add('preloader--hidden');
                                state.completed = true;
                            },
                        },
                        0.5,
                    )
                    .add(resolve, 0.65);
            });
        }

        function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
            return new Promise((resolve) => {
                if (asset instanceof HTMLImageElement) {
                    if (asset.complete) {
                        resolve();
                    } else {
                        asset.onload = () => resolve();
                        asset.onerror = () => resolve();
                    }
                }

                if (asset instanceof HTMLVideoElement) {
                    if (asset.readyState === 4) {
                        resolve();
                    } else {
                        asset.addEventListener('canplay', () => resolve(), { once: true });
                    }
                }
            });
        }

        const counterInstance = counter
            ? new CountUp(counter, 0, {
                  startVal: 0,
                  useEasing: true,
                  duration: 1.05,
              })
            : null;

        function setPercent(value: number) {
            counterInstance?.update(value);

            if (line) {
                line.style.transform = `scaleX(${value / 100})`;
            }
        }

        async function loadAssetsFromElement(element: Element | Document = document) {
            const images = Array.from(
                element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'),
            );
            const videos = Array.from(
                element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'),
            );
            const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

            if (images.length > 0) {
                await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
            }
        }

        async function loadAssets() {
            await loadAssetsFromElement(document.body);
            setPercent(100);
        }

        return { leave, loadAssets, state } as const;
    }

    const preloaderInstance = createPreloader();
    main?.classList.add('is-hidden');
    gsap.set(header, { yPercent: -100, opacity: 0 });

    // Initial load
    preloaderInstance
        .loadAssets()
        .then(() => timeout(1000))
        .then(() => preloaderInstance.leave())
        .then(() => {
            gsap.to(header, { yPercent: 0, opacity: 1, duration: 0.8, delay: 0.8 });

            setTimeout(() => {
                main?.classList.remove('is-hidden');
            }, 300);
        });
}

export default { init };
