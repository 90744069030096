import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import scrollIfHash from '../inits/scroll-if-hash';

const curtain = document.querySelector('.js-page-curtain');
const fragment = document.createDocumentFragment();
const squares = [];
const duration = 0.35;
const stagger = 0.03;
const ease = 'power3.out';

if (curtain) {
    for (let i = 0; i < 16; i++) {
        const el = document.createElement('div');
        fragment.appendChild(el);
        squares.push(el);
    }

    curtain.appendChild(fragment);
}

export default {
    name: 'default page transition',

    leave() {
        return new Promise((resolve) => {
            curtain?.classList.add('is-active');
            gsap.to(squares, {
                duration,
                scaleX: 1,
                stagger,
                ease,
                onComplete: resolve,
            });
        });
    },

    enter() {
        scrollIfHash();
    },

    after() {
        const scrollTriggers = ScrollTrigger.getAll();
        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.refresh();
            });
        }

        requestAnimationFrame(() => {
            gsap.to(squares, {
                duration,
                scaleX: 0,
                stagger,
                ease,
                onComplete: () => {
                    curtain?.classList.remove('is-active');
                },
            });
        });
    },
};
