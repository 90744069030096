import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
const header = document.querySelector<HTMLElement>('.js-header');

export default function scrollIfHash() {
    const headerHeight = header?.offsetHeight || 0;
    const { hash } = window.location;

    if (hash !== '') {
        const targetElement =
            (document.getElementById(hash) as HTMLElement | null) || document.querySelector<HTMLElement>(hash);

        if (targetElement) {
            const offset = targetElement.dataset.scrollToOffset ? parseFloat(targetElement.dataset.scrollToOffset) : 30;
            let scrollTarget: number;
            if (targetElement.dataset.calcScrollTop) {
                scrollTarget = parseFloat(targetElement.dataset.calcScrollTop);
            } else {
                scrollTarget = targetElement.offsetTop;
            }
            const distation = scrollTarget < 1000 ? 999 : Math.abs(window.pageYOffset - scrollTarget);
            let k = 0;
            if (distation > 10000) {
                k = 7000;
            } else if (distation > 5000) {
                k = 5000;
            } else if (distation > 3000) {
                k = 2500;
            } else if (distation > 1500) {
                k = 1500;
            } else {
                k = 1000;
            }
            const speed = distation / k;
            gsap.to(window, {
                duration: speed,
                scrollTo: {
                    y: targetElement,
                    offsetY: headerHeight + offset,
                },
                ease: 'power2.easeOut',
                onComplete: () => {
                    if (targetElement.classList.contains('js-collapse-item')) {
                        const elWrapper = targetElement.closest('.js-collapse-item-wrapper');
                        const collapse = elWrapper?.querySelector('app-collapse');
                        setTimeout(() => {
                            collapse?.expand();
                        }, k);
                    }
                    if (targetElement.classList.contains('js-tab-item')) {
                        const link = targetElement.querySelector('.tab__link');
                        const event = new Event('click', { bubbles: true, cancelable: false });
                        setTimeout(() => {
                            link?.dispatchEvent(event);
                        }, k);
                    }
                },
            });
        }
    } else {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }
}
