import './webpack-imports';
import './sentry';
import './polyfills';
import './custom-elements';
import barba from '@barba/core';
import gsap from 'gsap';
import delegate from 'delegate';

import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';

import inputMasks from './inits/masks';
import ajaxForms from './inits/ajax-forms';
import inputFocus from './inits/input-focus';
import ripple from './inits/ripple';
import smoothscroll from 'smoothscroll-polyfill';

import clipPathReveal from './inits/clip-path-reveal';
import fadeReveal from './inits/fade-reveal';

import defaultTransition from './transitions/default.js';

import { SplitText } from 'gsap/SplitText';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { SlowMo } from 'gsap/EasePack';
import ScrollTrigger from 'gsap/ScrollTrigger';
import scrollToElement from './inits/scroll-to-element';
import scrollIfHash from './inits/scroll-if-hash';

import preloader from './components/preloader';

document.documentElement.classList.add('js-ready');

gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(
    ScrollToPlugin,
    ScrollTrigger,
    DrawSVGPlugin,
    SplitText,
    CustomEase,
    Draggable,
    InertiaPlugin,
    SlowMo,
);

preloader.init();

if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}

scrollIfHash();

delegate(document, '.js-back-btn', 'click', (event: Event) => {
    event.preventDefault();
    window.history.back();
});

initBaseView();
const contactUsPopupEl = document.querySelector<HTMLElement>('app-lit-popup[data-lit-popup="contact-us"]');

if (contactUsPopupEl && contactUsPopupEl !== null) {
    ajaxForms.init(contactUsPopupEl);
    inputMasks.init(contactUsPopupEl);
    inputFocus.init(contactUsPopupEl);
}

const footer = document.querySelector('.js-footer');

clipPathReveal.init(footer);
fadeReveal.init(footer);
smoothscroll.polyfill();
ripple.init();
scrollToElement.init();

document.addEventListener(
    'preloader-leave',
    () => {
        barba.init({
            transitions: [defaultTransition],
            views: [
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                        (m) => m.default,
                    ),
                ),
                // eslint-disable-next-line max-len
                lazyView('about-page', () =>
                    import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
                ),
                lazyView('news-page', () =>
                    import(/* webpackChunkName: "news-page" */ './views/news').then((m) => m.default),
                ),
                lazyView('text-page', () =>
                    import(/* webpackChunkName: "text-page" */ './views/text').then((m) => m.default),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
            timeout: 5000,
            // requestError: (trigger, action, url, response) => {
            //     if (response.status === 404) {
            //         barba.go(`${ROOT_PATH}404/`);
            //     }
            //     return false;
            // },
            debug: NODE_ENV === 'development',
        });
    },
    {
        once: true,
    },
);
